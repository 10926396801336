<template>
  <div>
    <div v-if="data.length < 1">
      <b-card>
        <h4>ไม่มีรายการคืนเงิน</h4>
      </b-card>
    </div>
    <div v-else>
      <b-card v-for="(x, index) in data" :key="x._id">
        <div class="row">
          <div class="col-md-3">
            <h4 v-if="x.order">{{ x.order.order_id }}</h4>
            <div v-if="x.member">
              {{ x.member.first_name }} - {{ x.member.last_name }} <br />
              <div class="d-flex justify-content-between">
                <span>รับ</span>
                <span
                  >{{ x.start_date | day }} - {{ x.start_date | time }}</span
                >
              </div>
              <div class="d-flex justify-content-between">
                <span>คืน</span>
                <span>{{ x.end_date | day }} - {{ x.end_date | time }}</span>
              </div>
            </div>
            payment_id: {{ x._id }}
            <div class="d-flex justify-content-between">
              <span>ประเภทการชำระ</span>
              <span v-if="x.account">{{ x.account.type }}</span>
            </div>
            <div class="d-flex justify-content-between">
              <span>ประเภทเงิน</span>
              <span>{{ x.payment_type }}</span>
            </div>
            <b-button
              variant="relief-success"
              class="btn-icon mt-2"
              @click="open_new(x.order_id)"
            >
              <feather-icon icon="SearchIcon" /> Order
            </b-button>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-md-10">
                <div v-if="x.refund_detail">
                  <div v-if="x.refund_detail.payment_method === 'transfer'">
                    <h4 class="text-center">
                      คืนจาก: {{ x.account.display_name }}
                    </h4>
                    <b-list-group flush>
                      <b-list-group-item>
                        <div class="d-flex justify-content-between">
                          <b>ยอดโอนคืน</b>
                          {{ x.refund_detail.amount | number }}
                        </div>
                      </b-list-group-item>
                      <b-list-group-item>
                        <div class="d-flex justify-content-between">
                          <b>เลขที่บัญชี</b>
                          {{ x.refund_detail.account_number }}
                        </div>
                      </b-list-group-item>
                      <b-list-group-item>
                        <div class="d-flex justify-content-between">
                          <b>ชื่อบัญชี</b>
                          {{ x.refund_detail.account_name }}
                        </div>
                      </b-list-group-item>
                      <b-list-group-item>
                        <div class="d-flex justify-content-between">
                          <b>ธนาคาร</b>
                          {{ x.refund_detail.bank_name }}
                        </div>
                      </b-list-group-item>
                      <b-list-group-item>
                        <div class="d-flex justify-content-between">
                          <b>Note</b>
                          {{ x.refund_detail.note }}
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <!-- refund_status:{{ y.refund_status }} -->
                  </div>

                  <div v-else>
                    <h4>
                      คืนจาก: {{ x.refund_detail.payment_method | thai_text }}
                    </h4>
                    <b-list-group flush>
                      <b-list-group-item>
                        <div class="d-flex justify-content-between">
                          <b>ยอดโอนคืน</b>
                          {{ x.refund_detail.amount | number }}
                        </div>
                      </b-list-group-item>
                      <b-list-group-item>
                        <div class="d-flex justify-content-between">
                          <b>Note</b>
                          {{ x.refund_detail.note }}
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
              </div>
              <div
                class="col-md-2 d-flex align-items-center justify-content-center"
              >
                <b-button
                  @click="confirmRefund(x)"
                  size="sm"
                  variant="relief-warning"
                >
                  คืนเงินแล้ว
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BButton, BCard, BListGroup, BListGroupItem } from "bootstrap-vue";
import moment from "moment";

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
    loadData() {
      this.$http({
        method: "POST",
        url: `/payment/pending-refund`,
      }).then((x) => {
        this.data = x.data.data;
      });
    },
    confirmRefund(data) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ยืนยันโอนคืนแล้ว !!!", {
          title: "ยืนยันโอนคืนเงิน",
          // size: 'sm',
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const temp = data;
            temp.side = "withdraw";
            temp.main = true;
            delete temp.order.stock_image;
            this.$http({
              method: "POST",
              url: "/payment/refund",
              data: {
                payment_id: data._id,
                form: {
                  $set: { "refund_detail.refund_status": "refund_complete" },
                  pending_refund: false,
                },
                add_main: true,
                main_form: temp,
              },
            }).then((z) => {
              this.loadData();
            });
            console.log("cf transfer");
          }
        });
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    thai_text: (en_tx) => {
      console.log("en_tx", en_tx);
      const dict = [
        { en: "cash", th: "เงินสด" },
        { en: "cash999", th: "เงินสด" },
        { en: "card_verify", th: "กันวงเงิน Omise" },
      ];

      const match = dict.find((x) => x.en === en_tx);
      if (match) {
        return match.th;
      } else {
        // Return a default value or a specific message
        return en_tx; // This means "No data found" in Thai
      }
    },
  },
};
</script>

<style></style>
